// 菜单2品牌
<template>
  <div class="main">
    <div class="top">
      <div class="top-title">ALY HOME CASE</div>
      <div class="top-text">
        Here are the real cases of the homes of the customers we have served
        before. You can use them as references to choose products.
      </div>
      <div class="content-linex"></div>
    </div>
    <div class="content">
      <div class="second-list">
        <div
          class="second-item"
          v-for="(v, i) in ctIdList"
          :key="i"
          :class="{ 'second-item-active': categoryId == v.categoryId }"
          @click="selectItem(v.categoryId)"
        >
          <img
            :src="`${ossPrefix}${v.iconImage}`"
            class="child-icon"
            v-if="i != 0"
          />
          {{ v.name }}
        </div>
      </div>
      <div class="case-list" v-if="caseList.length > 0">
        <div
          class="case-item"
          @click="lookCase(index)"
          v-for="(item, index) in caseList"
          :key="index"
        >
          <img
            :src="`${ossPrefix}${item.imgList[0]}`"
            v-if="item.imgList[0]"
            class="case-cover"
          />
          <div class="case-title">{{ item.name }}</div>
          <div class="case-text1">
            {{ item.synopsis }}
          </div>
        </div>
      </div>
      <div class="no-list" v-else>
        <img
          src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/prod_icon_default@2x.png"
          class="no-list-pic"
        />
      </div>
      <!-- <div class="more" v-if="!hasMoreItems && caseList.length > 0">
        <div>There's nothing more left</div>
      </div> -->
    </div>
    <div class="content">
      <div class="content-top">
        <div class="content-liney"></div>
        <div class="content-title">ALY HOME Brands</div>
      </div>
      <div class="content-text">
        We work closely with leading names in the interiors industry to meet
        your needs and bring you the finest window furnishings available. Our
        partners are known and respected for their quality and products.
        Combined with ALY HOME's excellent service, you can be confident in
        finding the perfect solution for any of your window furnishing needs.
      </div>
      <img
        src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/bra_icon_logo2@2x.png"
        class="content-img"
      />
    </div>
    <el-dialog
      title="View case"
      :visible.sync="caseDialog"
      width="1400px"
      :close-on-click-modal="false"
      center
    >
      <div class="dialog-main">
        <div class="dialog-left">
          <el-carousel
            class="dialog_carousel"
            arrow="never"
            :interval="1500"
            v-if="currentCase.imgList"
          >
            <el-carousel-item
              v-for="(item, index) in currentCase.imgList"
              :key="index"
              class="swiper-item"
            >
              <img :src="`${ossPrefix}${item}`" class="swiper-pic" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="dialog-right">
          <div class="case-title">{{ currentCase.name }}</div>
          <div class="case-text2">{{ currentCase.synopsis }}</div>
        </div>
        <div class="navigation">
          <img
            src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/left.png"
            @click="preCase()"
          />
          <img
            src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/right.png"
            @click="nextCase()"
          />
        </div>
      </div>

      <div class="current">Sheet {{ caseIndex + 1 }}/{{ caseList.length }}</div>
    </el-dialog>
    <image-preview-modal
      v-if="isModalVisible"
      :images="imgsList"
      :currentIndex="currentIdx"
      :isVisible="isModalVisible"
      @close="closeModal"
    />
  </div>
</template>
<script>
import {
  usVisitLogInsert,
  usProduusProductCategoryParentListctList,
  usProductCaseNoPage,
} from "@/api/home.js";
import ImagePreviewModal from "@/components/ImagePreviewModal.vue";

export default {
  name: "Brands",
  components: { ImagePreviewModal },
  data() {
    return {
      ossPrefix: "https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/",
      currentPage: 1,
      pageSize: 12,
      totalPage: 0,
      categoryId: "",
      ctIdList: [],
      caseList: [],
      isModalVisible: false,
      currentIdx: 0,
      imgsList: [],
      caseIndex: 0,
      caseDialog: false,
    };
  },
  computed: {
    currentCase() {
      // console.log("Current", this.caseList);
      if (this.caseList.length > 0) {
        return this.caseList[this.caseIndex];
      } else {
        return {
          name: "",
          synopsis: "",
          imgList: [],
        };
      }
    },
    isFirstImage() {
      return this.caseIndex === 0;
    },
    isLastImage() {
      return this.caseIndex === this.caseList.length - 1;
    },
  },
  activated() {
    this.categoryId = "";
    this.getCaseTypeList();
    this.addVisitLogInsert();
  },
  methods: {
    lookCase(index) {
      this.caseIndex = index;
      this.caseDialog = true;
    },
    preCase() {
      if (!this.isFirstImage) {
        this.caseIndex--;
      } else {
        this.caseIndex = this.caseList.length - 1;
      }
    },
    nextCase() {
      if (!this.isLastImage) {
        this.caseIndex++;
      } else {
        this.caseIndex = 0;
      }
    },
    toPreviewImg(index) {
      let list = [];
      this.caseList.forEach((v) => {
        list.push(`${this.ossPrefix}${v.fileCover}`);
      });
      this.imgsList = list;
      this.currentIdx = index;
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    selectItem(id) {
      this.categoryId = id;
      this.getData();
    },
    // 向后端请求分类列表数据
    getCaseTypeList() {
      usProduusProductCategoryParentListctList().then((res) => {
        if (res.code == 1) {
          const val1 = {
            categoryId: "",
            name: "ALL",
          };
          const cate = res.data.dataList;
          cate.unshift(val1);
          this.ctIdList = cate;
          this.getData();
        } else {
          const val1 = {
            categoryId: "",
            name: "ALL",
          };
          const cate = [];
          cate.unshift(val1);
          this.ctIdList = cate;
          this.getData();
          this.$message.error(res.message);
        }
      });
    },
    // 向后端请求全部商品或分类商品数据
    getData() {
      // 如果分类列表为空则请求全部商品数据，否则请求分类商品数据
      usProductCaseNoPage(this.categoryId).then((res) => {
        // console.log(res);
        if (res.code == 1) {
          this.caseList = [...res.data.dataList];
        } else {
          this.caseList = [];
          this.$message.error(res.message);
        }
      });
    },
    addVisitLogInsert() {
      let website = window.location.href;
      let websiteTitle = "Case";
      // console.log(website, websiteTitle);
      usVisitLogInsert(website, websiteTitle, 0).then(() => {});
    },
  },
};
</script>
<style scoped>
.main {
  width: 100%;
  box-sizing: border-box;
}
.top {
  width: 1110px;
  margin: 58px auto;
}
.top-title {
  color: #293744;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 25px;
}
.top-text {
  color: #293744;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 21px;
}
.content {
  width: 1110px;
  margin: 64px auto;
}
.content-liney {
  width: 1px;
  height: 60px;
  background-color: #293744;
}
.content-linex {
  width: 1110px;
  height: 2px;
  background-color: #cccccc;
  margin-top: 41px;
}

.content-top {
  display: flex;
  align-items: flex-end;
  margin-bottom: 38px;
}
.content-title {
  font-size: 34px;
  font-weight: bold;
  color: #262626;
  margin-left: 16px;
}
.content-text {
  font-size: 16px;
  font-weight: 400;
  color: #262626;
  margin-bottom: 57px;
}
.content-img {
  width: 1110px;
}
.content {
  width: 1110px;
  margin: 0 auto 21px;
  font-size: 16px;
  color: #262626;
}
.second-list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0px;
  box-sizing: border-box;
  white-space: nowrap;
}
.second-item {
  display: flex;
  align-items: center;
  margin: 0 36px 25px 0;
  font-size: 24px;
  cursor: pointer;
}
.second-item-active {
  font-weight: bold;
  color: #caa68a;
}

.case-list {
  width: 100%;
  margin: 20px auto 35px;
  -moz-column-count: 4; /* Firefox */
  -webkit-column-count: 4; /* Safari 和 Chrome */
  column-count: 4;
  column-gap: 10px;
}

.case-item {
  -webkit-column-break-inside: avoid;
  break-inside: avoid; /*防止断点*/
  margin-bottom: 20px;
  padding: 10px 8px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.case-cover {
  width: 100%;
  height: auto;
  margin-bottom: 13px;
}
.case-title {
  font-size: 20px;
  font-weight: bold;
  color: #293744;
}
.case-text1 {
  margin-top: 20px;
  font-size: 18px;
  color: #999999;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
}
.case-text2 {
  margin-top: 20px;
  font-size: 18px;
  color: #999999;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
}

.more {
  padding: 0;
  text-align: center;
  margin: 0 auto 179px;
  color: #74c9c6;
  font-size: 30px;
  cursor: pointer;
}
.more-icon {
  margin: 0 auto;
  color: #74c9c6;
  font-size: 30px;
  cursor: pointer;
}
.no-list {
  width: 100%;
  height: 500px;
  margin: 0 auto 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  color: #cccccc;
  font-size: 26px;
}
.no-list-pic {
  width: 466px;
  height: 466px;
}
.child-icon {
  width: 22px;
  height: 22px;
  margin-right: 5px;
}

.dialog-main {
  width: 100%;
  height: 600px;
  position: relative;
  margin: -20px 0 10px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}
.dialog-left {
  width: 600px;
  height: 600px;
  position: relative;
}

.swiper-item {
  width: 100%;
  height: 600px;
}
.swiper-pic {
  width: 100%;
  height: 600px;
  position: absolute;
  left: 0;
  top: 0;
}
.dialog-left >>> .el-carousel__indicators--horizontal {
  position: absolute;
  bottom: 5px;
  left: 50%;
  height: 26px;
  max-width: 150px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 18px;
  z-index: 15 !important;
}
.dialog-left
  >>> .el-carousel__indicators--horizontal
  .el-carousel__indicator--horizontal {
  display: flex;
  align-items: center;
}
.dialog-left >>> .el-carousel__button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.dialog_carousel {
  width: 100% !important;
  height: 600px !important;
  z-index: 5 !important;
  overflow-y: hidden;
}
.dialog_carousel >>> .el-carousel__container {
  width: 100% !important;
  height: 600px !important;
  z-index: 9 !important;
}
.dialog-right {
  width: calc(100% - 650px);
  height: 600px;
}

.navigation {
  width: 1550px;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: -100px;
  top: calc(50% - 30px);
  z-index: 99;
}
.navigation img {
  width: 60px;
  height: 60px;
  cursor: pointer;
}
.current {
  position: absolute;
  bottom: 10px;
  right: 20px;
  font-size: 20px;
  text-align: right;
}
</style>
