<template>
  <div class="bot-main">
    <div class="part2">
      <div class="part-left">
        <div class="part-title">
          <div class="part-line" style="background: #fff"></div>
          <div class="part-name" style="color: #fff">Trade With ALY HOME</div>
        </div>
        <div class="part-text" style="color: #fff">
          Contact Us,for fine interior products at fair prices. As a valued
          trade customer, you'll be the first to hear about new designs and
          collections, with access to a wide range of products and services that
          will be tailored to your specific needs.
        </div>
        <div class="part1-btn btn black-hover-border" @click="toContactUs()">
          CONTACT FORM<span class="swiper-text">></span>
        </div>
      </div>
      <div class="part-right">
        <img
          class="part-right-img"
          src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/updateImgs/home_bg_cont@2x.png"
        />
        <img
          class="part-right-icon"
          src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/home_icon_sign@2x.png"
        />
      </div>
    </div>
    <div class="part1">
      <div class="part1-item">
        <div class="part1-text">Call Aly Home</div>
        <div class="part1-phone">TEL：0086-574-62165998</div>
        <div class="part1-phone">E-mail：sale@yyaly.com</div>
        <div class="part1-text">Mon - Fri 9am - 5pm</div>
      </div>
      <div class="part1-item">
        <div class="part1-text">Contact Aly Home</div>
        <div class="part1-text">Leave a message and we will be in touch</div>
        <div class="part1-btn btn black-hover-border" @click="toContactUs()">
          CONTACT FORM<span class="swiper-text">></span>
        </div>
      </div>
    </div>
    <div class="part4">
      <div class="part4-list">
        <div class="part4-item" @click="toContactUs()">CONTACT US</div>
        <div class="part4-item" @click="toPrivacy()">PRIVACY</div>
        <div class="part4-item" @click="toCookies()">COOKIES</div>
        <div class="part4-item" @click="toBrands()">CASE</div>
      </div>
      <img
        src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/logo@2x.png"
        class="part4-logo"
        @click="toHome()"
      />
      <div class="part4-line"></div>
      <div class="part4-fot">
        <div class="part4-fot-text">
          ALY HOME is a supplier of high quality home and commercial interior
          products, place of registration: China, Registered office:No. 23, Ruhu
          West Road, Simen, Yuyao, Ningbo City, HD5 0RH
        </div>
        <div class="part4-fot-text">© ALY HOME 2024</div>
        <div class="part4-fot-text">All rights reserved</div>
      </div>
    </div>
  </div>
</template>
<script>
import {} from "@/api/home.js";
export default {
  name: "BotFooter",
  components: {},
  data() {
    return {
      ossPrefix: "https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/",
    };
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    toHome() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 可选,设置为 'smooth' 可以使滚动动画平滑
      });
      this.$router.push({
        name: "Home",
      });
    },
    toContactUs() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 可选,设置为 'smooth' 可以使滚动动画平滑
      });
      this.$router.push({
        name: "ContactUs",
      });
    },
    toPrivacy() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 可选,设置为 'smooth' 可以使滚动动画平滑
      });
      this.$router.push({
        name: "Privacy",
      });
    },
    toCookies() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 可选,设置为 'smooth' 可以使滚动动画平滑
      });
      this.$router.push({
        name: "Cookies",
      });
    },
    toBrands() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 可选,设置为 'smooth' 可以使滚动动画平滑
      });
      this.$router.push({
        name: "Brands",
      });
    },
  },
};
</script>
<style scoped>
.bot-main {
  width: 100%;
  box-sizing: border-box;
  padding: 56px 0 0;
}
.part1 {
  width: 1920px;
  height: 220px;
  background-image: url("https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/home_bg_cont@2x.png");
  background-size: cover;
  padding: 30px 512px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.part1-item {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.part1-text {
  font-size: 16px;
  font-weight: 300;
  color: #fff;
}
.part1-phone {
  font-size: 34px;
  font-weight: bold;
  color: #fff;
}

.part1-btn {
  display: inline-block;
  padding: 16px 68px;
  border: 1px solid #fff;
  font-size: 16px;
  color: #fff;
  margin: 0 0 0 18px;
  cursor: pointer;
}
.part-name {
  font-size: 34px;
  font-weight: bold;
  color: #262626;
  margin: 0 0 8px 18px;
}
.part-text {
  font-size: 16px;
  color: #262626;
  margin: 0 0 15px 18px;
}
.part-line {
  width: 1px;
  height: 100px;
  background-color: #293744;
}
.part2 {
  width: 1920px;
  height: 483px;
  padding: 56px 405px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background-image: url("https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/home_bg_con@2x.png");
  background-size: cover;
}
.part-left {
  width: 625px;
  height: 445px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
}
.btn {
  width: 290px;
  box-sizing: border-box;
}
.part-right {
  width: 445px;
  height: 445px;
  position: relative;
}
.part-right-img {
  width: 445px;
  height: 445px;
  position: absolute;
  right: 0;
  top: -47px;
}
.part-right-icon {
  width: 252px;
  height: 252px;
  position: absolute;
  bottom: 12px;
  right: -38px;
  z-index: 9;
}
.part4 {
  width: 1920px;
  height: 340px;
  background-color: #e6e8e8;
  padding: 34px 0 26px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.part4-list {
  width: 1110px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 16px;
  color: #293744;
}
.part4-item {
  cursor: pointer;
}
.part4-item:hover {
  border-bottom: 1px solid #293744;
}
.part4-logo {
  width: 60px;
  height: 60px;
  margin-bottom: 30px;
  cursor: pointer;
}
.part4-line {
  width: 100%;
  height: 1px;
  background-color: #8e9c9d;
}
.part4-fot {
  width: 1110px;
  text-align: center;
  font-size: 12px;
  color: #293744;
}
.white-hover:hover {
  background-color: #fff;
  color: #293744;
}
.black-hover:hover {
  background-color: #5f6467;
  color: #fff;
}
.black-hover-border:hover {
  background-color: #5f6467;
  color: #fff;
  border: 1px solid #5f6467;
}
</style>
