// 菜单5下载
<template>
  <div class="main">
    <div class="top">
      <div class="top-title">DOCUMENT</div>
      <div class="top-text">
        Here is the detailed introduction of our mainstream products.
      </div>
      <div class="top-text">
        You can claim the interested files to your email.
      </div>
      <div class="content-linex"></div>
    </div>

    <div class="content items">
      <div class="item" v-for="(item, index) in dataList" :key="index">
        <img
          class="item-icon"
          :src="`${ossPrefix}${item.iconImage}`"
          v-if="item.iconImage"
        />
        <div class="item-main">
          <div class="item-name">{{ item.name }}</div>
          <div class="item-text-hidden" v-html="item.explain"></div>
        </div>
        <div class="item-btns">
          <div class="item-btn" @click="toDownload(item.categoryId)">
            Download Producet PDF >
          </div>
          <div class="item-btn" @click="toTypeDetails(item.categoryId)">
            View Product Details >
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="Fill in email address"
      :visible.sync="emailVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <div class="email-tips">
        Please provide your real email address so that we can send you the
        product image
      </div>

      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item prop="email">
          <el-input
            v-model="form.email"
            placeholder="Please enter your email address"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm('form')">Submit</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  usVisitLogInsert,
  usProductCategoryAlbumList,
  getIpAddress,
  usApplyAdd,
} from "@/api/home.js";

export default {
  name: "Document",
  data() {
    return {
      ossPrefix: "https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/",
      dataList: [],
      emailVisible: false,
      categoryId: "",
      form: {
        email: "",
      },
      ipAddress: "",
      rules: {
        email: [
          {
            required: true,
            message: "Please enter your email address",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Please enter the correct email format",
            trigger: "blur",
          },
        ],
      },
    };
  },
  activated() {
    this.getData();
    this.addVisitLogInsert();
  },
  methods: {
    toDownload(id) {
      //   console.log(id, ">>>>");
      this.categoryId = id;
      this.form = {
        email: "",
      };
      this.emailVisible = true;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 提交表单逻辑
          let obj = {
            categoryId: this.categoryId,
            mailbox: this.form.email,
            ipAddress: this.ipAddress,
          };
          usApplyAdd(obj).then((res) => {
            this.emailVisible = false;
            if (res.code == 1) {
              this.$message.success(res.message);
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },

    // 向后端请求全部商品或分类商品数据
    getData() {
      getIpAddress().then((res) => {
        if (res.code == 1) {
          this.ipAddress = res.data.ipAddress;
        } else {
          this.ipAddress = "";
          this.$message.error(res.message);
        }
      });
      usProductCategoryAlbumList().then((res) => {
        if (res.code == 1) {
          this.dataList = [...res.data.dataList];
        } else {
          this.dataList = [];
          this.$message.error(res.message);
        }
      });
    },
    toTypeDetails(id) {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 可选,设置为 'smooth' 可以使滚动动画平滑
      });
      this.$router.push({
        name: "ProType",
        query: {
          pid: id,
        },
      });
    },
    addVisitLogInsert() {
      let website = window.location.href;
      let websiteTitle = "Document";
      // console.log(website, websiteTitle);
      usVisitLogInsert(website, websiteTitle, 0).then(() => {});
    },
  },
};
</script>
<style scoped>
.main {
  width: 100%;
  box-sizing: border-box;
}
.top {
  width: 1110px;
  margin: 58px auto 0;
}
.top-title {
  color: #293744;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 25px;
}
.top-text {
  color: #293744;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 21px;
}
.content {
  width: 1110px;
  margin: 64px auto;
}
.content-liney {
  width: 1px;
  height: 60px;
  background-color: #293744;
}
.content-linex {
  width: 1110px;
  height: 2px;
  background-color: #cccccc;
  margin-top: 41px;
}

.content-top {
  display: flex;
  align-items: flex-end;
  margin-bottom: 38px;
}
.content-title {
  font-size: 34px;
  font-weight: bold;
  color: #262626;
  margin-left: 16px;
}
.content-text {
  font-size: 16px;
  font-weight: 400;
  color: #262626;
  margin-bottom: 57px;
}
.content-img {
  width: 1110px;
}
.content {
  width: 1110px;
  margin: 0 auto 21px;
  font-size: 16px;
  color: #262626;
}
.items {
  margin: 58px auto;
}
.item {
  width: 100%;
  padding: 57px 58px;
  background-color: #caa68a;
  margin: 0 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.item-icon {
  width: 102px;
  height: 102px;
}
.item-main {
  width: calc(100% - 370px);
  height: 120px;
  display: flex;
  flex-direction: column;
  color: #ffffff;
}
.item-name {
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 20px;
}
.item-text-hidden {
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
}
.item-btns {
  height: 130px;
  padding-top: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.item-btn {
  width: 220px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  color: #262626;
  background-color: #f5f5f7;
  cursor: pointer;
}
.item-btn:hover {
  background-color: #5f6467;
  color: #fff;
}
.email-tips {
  margin: -15px 0 15px;
}
</style>
