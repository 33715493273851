// 产品分类
<template>
  <div class="main">
    <div class="type-top">
      <!-- <img
        v-if="parentDetails.iconImage"
        :src="`${ossPrefix}${parentDetails.iconImage}`"
        class="top-bg"
      /> -->
      <img
        v-if="parentDetails.iconImage"
        :src="`${ossPrefix}${parentDetails.iconImage}`"
        class="top-icon"
      />
      <div class="top-title">{{ parentDetails.name }}</div>
      <div class="top-text">
        {{ parentDetails.explain }}
      </div>
    </div>
    <div class="type-mian">
      <div class="type-left">
        <div
          class="type-item"
          v-for="(item, index) in typeList"
          :key="index"
          :class="[item.categoryId == categoryId && 'type-item-active']"
          @click="changeType(item.categoryId)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="type-right">
        <div class="type-right-num">{{ proList.length }} Item(s) Found</div>
        <div class="type-pro-list" v-if="proList.length > 0">
          <div
            class="type-pro-item"
            v-for="(item, index) in proList"
            :key="index"
            @click="toProDetail(item.productId)"
          >
            <img
              :src="`${ossPrefix}${item.cover}`"
              class="type-pro-item-cover"
            />
            <div class="type-pro-item-name">{{ item.productName }}</div>
            <div class="type-pro-item-btn">View More</div>
          </div>
        </div>
        <div v-else class="no-data">
          <img
            src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/prod_icon_default@2x.png"
            class="no-data-cover"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  usProductCategoryChildList,
  usProductListV1,
  usVisitLogInsert,
  getIpAddress,
} from "@/api/home.js";
import "@/style/proType.css";
export default {
  name: "proType",
  data() {
    return {
      ossPrefix: "https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/",
      pid: "",
      categoryId: "",
      typeList: [
        {
          categoryId: "",
          name: "All",
        },
      ],
      proList: [],
      parentDetails: {},
      ipAddress: "",
    };
  },
  beforeRouteUpdate(to, from, next) {
    // console.log("beforeRoute", to, from, next);
    this.pid = to.query.pid;
    this.categoryId = "";
    this.getTypeList(this.pid);
    this.getProList(this.pid, this.categoryId);
    next();
  },
  activated() {
    this.pid = this.$route.query.pid;
    this.categoryId = "";
    this.getIpInfo();
    this.getTypeList(this.pid);
    this.getProList(this.pid, this.categoryId);
  },
  methods: {
    getIpInfo() {
      getIpAddress().then((res) => {
        if (res.code == 1) {
          this.ipAddress = res.data.ipAddress;
        } else {
          this.ipAddress = "";
          this.$message.error(res.message);
        }
      });
    },
    addVisitLogInsert(websiteTitle) {
      let website = window.location.href;
      usVisitLogInsert(website, websiteTitle, 0).then(() => {});
    },
    changeType(id) {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 可选,设置为 'smooth' 可以使滚动动画平滑
      });
      this.categoryId = id;
      this.getProList(this.pid, this.categoryId);
    },
    toProDetail(id) {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 可选,设置为 'smooth' 可以使滚动动画平滑
      });
      this.$router.push({
        name: "ProDetails",
        query: {
          productId: id,
        },
      });
    },
    getTypeList(id) {
      // console.log("getTypeList", id);
      usProductCategoryChildList(id).then((res) => {
        // console.log("typeList", res);
        if (res.code == 1) {
          this.parentDetails = res.data.parentDetails;
          this.typeList = [
            {
              categoryId: "",
              name: "All",
            },
            ...res.data.dataList,
          ];
          this.addVisitLogInsert(this.parentDetails.name);
        } else {
          this.parentDetails = {};
          this.typeList = [
            {
              categoryId: "",
              name: "All",
            },
          ];
          this.$message.error(res.message);
        }
      });
    },
    getProList(id1, id2) {
      // console.log("getProList", id1, id2);
      usProductListV1(id1, id2).then((res) => {
        // console.log("proList", res);
        if (res.code == 1) {
          this.proList = res.data.dataList;
        } else {
          this.proList = [];
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>
<style scoped>
.main {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
  box-sizing: border-box;
}
</style>
